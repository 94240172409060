@font-face {
    font-family: "built-tilting";
    src: url("../fonts/built_titling/built\ titling\ bd.ttf");
  }
  .nav-item .dropdown-menu{
      display: none;
      transition: display  2.2s ease-in-out;
  }
  .nav-item:hover .dropdown-menu {
      display: block;
  }
  .navbar-brand{
    font-family: built-tilting;
    font-size: 2rem;
  }

  .nav-link{
    font-weight: bold;
  }