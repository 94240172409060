.product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .product {
    flex: 0 0 calc(25% - 15px);
    max-width: calc(25% - 15px);
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .product-name {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .product-price {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  @media (max-width: 1200px) {
    .product {
      flex: 0 0 calc(33.333% - 13.333px);
      max-width: calc(33.333% - 13.333px);
    }
  }
  
  @media (max-width: 992px) {
    .product {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 576px) {
    .product {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }